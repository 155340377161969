/* SLIDER */

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

.slider-container {
  background-image: url("../images/banner.gif");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: -30px -20px;
  padding: 20px 20px 0px;
  .slider-title {
    animation: pulse 2s infinite;
    color: #ffffff;
    text-shadow: 0px 0px 10px rgba(188, 98, 255, 0.8);
    text-align: center;
    font-size: 2;
    font-weight: bold;
    color: $primary;
    cursor: pointer;
    &:hover {
      animation: bounce 0.5s ease;
    }
  }
  .swiper {
    width: 100%;
    padding: 16px 0 20px;
    margin-bottom: 50px;
    .swiper-slide {
      position: relative;
      width: 600px;
      img {
        border-radius: 20px;
        height: 300px;
        object-fit: cover;
        border: 2px solid rgba(159, 160, 168, 0.5);
      }
      @media (max-width: 1250px) {
        width: 500px;
        img {
          border-radius: 16px;
          height: 280px;
        }
      }
      @media (max-width: 1100px) {
        width: 410px;
        img {
          height: 220px;
        }
      }
      @media (max-width: 910px) {
        width: 350px;

        img {
          height: 180px;
        }
      }
      @media (max-width: 825px) {
        width: 320px;

        img {
          height: 200px;
        }
      }
      @media (max-width: 700px) {
        width: 420px;
      }
      @media (max-width: 580px) {
        width: 340px;
        img {
          height: 180px;
        }
      }
      @media (max-width: 450px) {
        width: 320px;
      }
      .slide-overlay {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        row-gap: 12px;
        position: absolute;
        inset: 0;
        width: 100%;
        background: linear-gradient(
          240deg,
          transparent,
          transparent,
          rgba(38, 21, 149, 0.8)
        );
        border-radius: 20px;
        padding: 30px;
        h2 {
          font-size: clamp(1.2rem, 3vw, 2.2rem);
          font-weight: 700;
        }
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 6px;
          width: max-content;
          padding: 6px;
          background: #e9e6eb2a;
          color: #e8e5e5;
          border: 1px solid rgba(159, 160, 168, 0.4);
          border-radius: 12px;
          outline: 0;
          font-size: clamp(0.7rem, 3vw, 1rem);
          font-weight: 500;
          text-transform: uppercase;
          box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
            rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
          transition: all 0.4s linear;
          cursor: pointer;
          &:is(:hover, :focus-visible) {
            background: #e9e6eb;
            color: #000;
          }
        }
        @media (max-width: 700px) {
          row-gap: 12px;
          padding: 20px 30px;
        }
        @media (max-width: 825px) {
          row-gap: 8px;
          padding: 12px 36px;
          button {
            padding: 8px 12px;
          }
        }
        @media (max-width: 450px) {
          row-gap: 8px;
          padding: 12px 36px;
        }
      }
    }
    .swiper-pagination {
      bottom: -4px !important;
      .swiper-pagination-bullet {
        width: 10px !important;
        height: 10px !important;
        background-color: #ffffffe7 !important;
        transition: all 0.3s ease-in-out !important;
      }

      .swiper-pagination-bullet-active {
        background-color: #fff;
        width: 18px !important;
        border-radius: 8px !important;
      }
    }
  }
}

.calendar-container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  color: #fff;
  border-radius: 10px;
  padding: 20px;
  @media (max-width: 767px) {
    display: none;
  }

  .calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    h3 {
      margin: 0;
      font-size: 1.2rem;
    }

    button {
      background: #3cbabb;
      color: #fff;
      border: none;
      border-radius: 5px;
      padding: 5px 10px;
      cursor: pointer;

      &:hover {
        background: #3cbabb;
      }
    }
  }

  .calendar-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px;

    .calendar-day-name {
      font-weight: bold;
      text-align: center;
      padding: 5px 0;
      border-bottom: 1px solid #3cbabb;
      cursor: pointer;
    }

    .calendar-day {
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 5px;
      transition: background 0.3s;
      cursor: pointer;

      &.current-day {
        background: rgba(255, 255, 255, 0.3);
        font-weight: bold;
      }

      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }
    }
  }
}

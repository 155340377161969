.sessions-container {
  color: #fff;
  padding: 20px;
  border-radius: 8px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h2 {
      margin: 0;
      font-size: 1.5rem;
    }

    .actions {
      display: flex;
      align-items: center;
      color: #fff;
      border: 6px;
      font-size: 16px;
      background-color: #3cbabb;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #bc62ff;
      }
    }
  }

  .table {
    text-align: center;
    --bs-table-color: #fff;
    --bs-table-bg: transparent;
    --bs-table-border-color: #bc62ff;
    --bs-table-striped-bg: #bc62ff;
    --bs-table-striped-color: #fff;
    --bs-table-active-bg: #bc62ff;
    --bs-table-active-color: #fff;
    --bs-table-hover-bg: transparent;
    --bs-table-hover-color: #fff;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color);
    tbody {
      tr {
        .co-host-images {
          display: flex;
          align-items: center;
          justify-content: center;
          .co-host-image {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;
          }
        }
        td {
          vertical-align: middle;
        }
      }
    }
  }
}

.set-password {
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  .set-password-container {
    background-color: #1a1a1a;
    color: #fff;
    padding: 2rem;
    border-radius: 16px;
    box-shadow: 0 4px 12px rgba(1, 1, 1, 0.5);
    .set-password-title {
      text-align: center;
      color: $primary;
      font-size: 40px;
      font-weight: bold;
    }
    .error-message {
      color: #ff4d4d;
      font-size: 12px;
    }
    .set-password-form {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      .input-group {
        flex-direction: column;
        margin-bottom: 20px;
        .input-with-icon {
          display: flex;
          align-items: center;
          position: relative;
          input {
            width: 100%;
            border: unset;
            border-bottom: 2px solid #fff;
            background-color: #2c2c2e;
            color: #fff;
            &:focus-visible,
            &:focus,
            &:visited {
              box-shadow: none !important;
              color: #fff !important;
            }
          }
          .icon-eye {
            position: absolute;
            right: 10px;
            color: #888;
          }
        }
      }
      .set-password-btn {
        background-color: #1a1b26;
        font-size: 14px;
        font-weight: 500;
        color: $primary;
        padding: 10px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        margin-bottom: 20px;
        &:disabled {
          background-color: #777;
        }
      }
    }
  }
}

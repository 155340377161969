/********** Template SCSS **********/
:root {
  --primary: #bc62ff;
  --secondary: #191c24;
  --light: #6c7293;
  --dark: #000000;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 45px;
  bottom: 45px;
  z-index: 99;
}

/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-out, visibility 0s linear 0.5s;
  z-index: 99999;

  &.show {
    transition: opacity 0.5s ease-out, visibility 0s linear 0s;
    visibility: visible;
    opacity: 1;
  }
}

/*** Button ***/
.btn {
  transition: 0.5s;

  &-square,
  &-sm-square,
  &-lg-square {
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    border-radius: 50px;
  }

  &-square {
    width: 38px;
    height: 38px;
  }

  &-sm-square {
    width: 32px;
    height: 32px;
  }

  &-lg-square {
    width: 48px;
    height: 48px;
  }
}
.bg-secondary {
  background-color: var(--secondary) !important;
}
/*** Layout ***/
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 250px;
  height: 100vh;
  overflow-y: auto;
  background: var(--secondary);
  transition: 0.5s;
  z-index: 999;

  &.open {
    margin-left: -250px;
  }

  .navbar {
    z-index: 1;
    .navbar-brand {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 64px;
    }

    .navbar-nav {
      display: flex;
      gap: 15px;
      .nav-link {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 7px 20px;
        color: var(--light);
        font-weight: 500;
        border-left: 3px solid var(--secondary);
        border-radius: 0 30px 30px 0;
        outline: none;
        height: 60px;

        &:hover,
        &.active {
          color: var(--primary);
          background: var(--dark);
          border-color: var(--primary);
        }

        i {
          width: 40px;
          height: 40px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background: var(--dark);
          border-radius: 40px;

          &:hover,
          &.active {
            background: var(--secondary);
          }
        }
      }
    }
  }
}

.admin-content {
  margin-left: 250px;
  min-height: 100vh;
  background: var(--dark);
  transition: 0.5s;

  &.open {
    width: 100%;
    margin-left: 0;
  }

  .text-primary,
  a {
    color: var(--primary) !important;
  }

  .link-tag {
    color: var(--primary) !important;
    cursor: pointer;
    text-decoration: underline;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #fff !important;
  }

  .table-dark {
    --bs-table-color: #fff;
    --bs-table-bg: var(--secondary) !important;
    --bs-table-border-color: #4d5154;
    --bs-table-striped-bg: #2c3034;
    --bs-table-striped-color: #fff;
    --bs-table-active-bg: #373b3e;
    --bs-table-active-color: #fff;
    --bs-table-hover-bg: var(--secondary) !important;
    --bs-table-hover-color: #fff;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color);
    .info {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }
  .navbar {
    .navbar-nav {
      .nav-link {
        margin-left: 25px;
        padding: 12px 0;
        color: var(--light);

        &:hover,
        &.active {
          color: var(--primary);
        }

        i {
          width: 40px;
          height: 40px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background: var(--dark);
          border-radius: 40px;
        }
      }
    }

    .sidebar-toggler {
      color: var(--primary);
    }
  }
}

@media (min-width: 992px) {
  .sidebar {
    margin-left: 0;
  }

  .admin-content {
    width: calc(100% - 250px);
  }
}

@media (max-width: 991.98px) {
  .sidebar {
    margin-left: -250px;

    &.open {
      margin-left: 0;
    }
  }

  .admin-content {
    width: 100%;
    margin-left: 0;
  }
}

@media (max-width: 575.98px) {
  .admin-content .navbar .navbar-nav .nav-link {
    margin-left: 15px;
  }
}

.content-wrapper {
  color: #fff;
  padding: 20px;
  border-radius: 8px;

  .header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .left-content {
      display: flex;
      align-items: center;
      gap: 10px;

      h2 {
        margin: 0;
        font-size: 1.5rem;
      }
      button {
        cursor: pointer;
      }
    }

    .actions {
      display: flex;
      align-items: center;
      color: #fff;
      border: 6px;
      font-size: 16px;
      .search-input {
        max-width: 200px;
        color: #fff !important;
      }
    }
  }

  .table {
    tbody {
      tr {
        .info {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;
          }
        }
        td {
          vertical-align: middle;
          word-wrap: break-word;
          white-space: normal;
          border: 1px solid #ffff;
        }
      }
    }
  }

  .record-container {
    .record {
      padding: 15px;

      .record-list {
        list-style: none;
        padding: 0;

        li {
          display: flex;
          gap: 10px;
          justify-content: start;
          align-items: center;
          margin-bottom: 15px;
          font-size: 16px;

          strong {
            font-weight: bold;
          }

          .tag {
            display: inline-block;
            padding: 5px 10px;
            border-radius: 5px;
            font-size: 12px;
            font-weight: bold;
            color: white;

            &.blue {
              background-color: #1890ff;
            }
            &.green {
              background-color: #52c41a;
            }
            &.purple {
              background-color: #722ed1;
            }
            &.red {
              background-color: #ff4d4f;
            }
          }
        }
      }
    }
  }
}

.feedback-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  color: #fff;

  h2 {
    text-align: center;
    margin-bottom: 20px;
  }

  .form-group {
    margin-bottom: 15px;

    label {
      display: block;
      margin-bottom: 5px;
      font-weight: bold;
    }

    .stars {
      display: flex;
      gap: 5px;
      cursor: pointer;

      .star {
        font-size: 24px;
        color: #ccc;
        transition: color 0.3s;

        &.filled {
          color: #ffd700; // Gold color for filled stars
        }

        &:hover {
          color: #ffbf00;
        }
      }
    }

    textarea {
      max-width: 100%;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      resize: none;
      height: 80px;
    }
  }

  button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #bc62ff;
    color: white;

    &:hover {
      background-color: #bc62ff;
    }
  }
}

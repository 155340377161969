@import "../../assets/styles/variables.scss";
@import "../../assets/styles/media.scss";

.custom-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 35px;
  width: 100%;
  z-index: 1;
  .brand-con {
    display: flex;
    gap: 30px;
    align-items: center;
  }
  .mob-search-con,
  .search-con {
    background-color: #141517;
    border: 1px solid #494d55;
    color: #fff !important;
    width: 30%;
    position: relative;
    @include phone {
      display: none;
    }
    input {
      &:focus,
      &:visited {
        box-shadow: none !important;
        color: #fff !important;
      }
    }
    .dropdown {
      list-style-type: none;
      padding: 0;
      margin: 0;
      position: absolute;
      top: 50px;
      width: 100%;
      background-color: #141517;
      border-radius: 20px !important;
      border: 1px solid #494d55;
      max-height: 200px;
      overflow-y: auto;
      z-index: 1;
      li {
        padding: 10px 20px;
        border-bottom: 1px solid #494d55;
        display: flex;
        align-items: center;
        &:hover {
          background-color: #070707;
          cursor: pointer;
        }
      }
    }
  }
  .buttons {
    display: flex;
    gap: 15px;
    align-items: center;
    .search-icon {
      display: none;
      @include phone {
        display: block;
      }
    }
    .notification-bell {
      position: relative;
      display: inline-block;
    }
    .desktop-buttons {
      display: flex;
      gap: 10px;
      align-items: center;
      @include phone {
        display: none;
      }
      .btn {
        color: #fff;
        border: 6px;
        font-size: 14px;

        &.login-btn {
          background-color: $secondary;
        }
        &.register-btn {
          background-color: $primary;
        }
      }
    }
    .mobile-buttons {
      display: none;
      @include phone {
        display: flex;
        gap: 20px;
        align-items: center;
      }
    }
  }
}

.profile {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  text-align: end;

  h3 {
    text-align: end;
    line-height: 1;
    margin-bottom: 4px;
    font-weight: 600;
  }

  p {
    line-height: 1;
    font-size: 14px;
    opacity: 0.6;
  }

  .img-box {
    position: relative;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    overflow: hidden;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .profile-menu {
    position: absolute;
    top: calc(100% + 10px);
    width: 250px;
    min-height: 100px;
    background: linear-gradient(to bottom, #2b1055, #7597de);
    box-shadow: inset 2px 2px 1px rgba(251, 251, 254, 0.3),
      inset -2px -2px 1px rgba(251, 251, 254, 0.2);
    border-radius: 1rem;
    backdrop-filter: blur(10px);
    opacity: 0;
    transform: translateY(-10px);
    visibility: hidden;
    transition: 300ms;

    &.active {
      opacity: 1;
      transform: translateY(0);
      visibility: visible;
      z-index: 1;
    }

    ul {
      position: relative;
      display: flex;
      flex-direction: column;
      z-index: 10;
      padding: 0;
      margin: 0;
      li {
        text-decoration: none;
        color: #fff;
        display: flex;
        align-items: center;
        padding: 15px 20px;
        gap: 15px;
        list-style: none;
        border-radius: 1rem;
        &:hover {
          background: linear-gradient(to bottom, #2b1055, #7597de);
        }
        i {
          font-size: 1.2em;
        }
        img {
          width: 20px;
          height: 20px;
        }
        span {
          text-align: left;
        }
      }
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.mob-search-con {
  @include phone {
    display: block !important;
  }
}

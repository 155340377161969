$h3-font-size: clamp(0.5rem, 2vw, 0.85rem);
$small-font-size: clamp(0.5rem, 2vw, 0.85rem);

.content {
  display: grid;
  grid-template-columns: 75% 25%;
  @include tab {
    grid-template-columns: 70% 30%;
  }
  @include tabv {
    grid-template-columns: 65% 35%;
  }
  @include phone {
    grid-template-columns: 100%;
  }

  .content-container {
    &.trending {
      display: none;
      @include phone {
        display: block;
      }
    }
    .content-title {
      color: #fff;
      font-weight: bold;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    }
    .card__container {
      display: flex;
      gap: 1.5rem;
      overflow-x: auto;
      padding-bottom: 1rem;
    }
  }
  .left-content {
    padding: 30px 20px;
    color: #e5e5e5;
    height: calc(100vh - 60px);
    overflow-y: scroll;
    @media (max-width: 1100px) {
      padding: 40px 20px 20px;
    }
    @media (max-width: 910px) {
      padding: 30px 20px 20px;
    }
    @include phone {
      height: calc(100vh - 120px);
    }
    .footer {
      display: none;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
      .social-links {
        display: flex;
        gap: 15px;
        div {
          width: 20px;
          height: 20px;
        }
      }
      .contact-info {
        display: flex;
        gap: 15px;
        div {
          display: flex;
          gap: 15px;
          align-items: center;
          span {
            color: #fff;
          }
        }
      }
      .copyright {
        font-size: 16px;
        color: #fff;
      }
    }
  }
}

.right-content {
  display: flex;
  flex-direction: column;
  border-radius: 0 15px 15px 0;
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  color: #e5e5e5;
  height: calc(100vh - 60px);
  overflow-y: scroll;
  @include phone {
    display: none;
  }
  .recommended-users {
    padding: 30px 20px;
    h1 {
      color: #fff;
      font-weight: bold;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    }
    .user-container {
      align-items: center;
      .user-info {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        align-items: center;
        gap: 20px;
        .user-img {
          position: relative;
          width: 60px;
          border-radius: 6px;
          &:hover {
            .overlay {
              opacity: 1;
            }
          }
          img {
            // aspect-ratio: 4/3;
            border-radius: inherit;
            object-fit: cover;
            border: 2px solid rgba(159, 160, 168, 0.5);
            box-shadow: rgba(221, 221, 221, 0.3) 0px 6px 18px -3px,
              rgba(221, 221, 221, 0.2) 0px -3px 0px inset;
          }
          .overlay {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            inset: 0;
            width: 100%;
            background-color: rgba(169, 150, 253, 0.6);
            text-decoration: none;
            color: #fff;
            border-radius: inherit;
            opacity: 0;
            transition: all 0.4s linear;
            cursor: pointer;
          }
        }

        .user-title {
          h2 {
            font-size: 1rem;
          }
          p,
          span {
            font-size: 0.8rem;
            font-weight: 300;
          }
          p {
            opacity: 0.8;
          }
        }
      }
    }
  }
  .right-side {
    background: linear-gradient(
      to bottom,
      #bc62ff 30%,
      #bc62c5 60%,
      #3cbabb 100%
    );
    border-top-left-radius: 100px;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    gap: 10px;
    padding: 20px 10px;
    h1 {
      font-weight: bold;
    }
    p {
      font-size: 18px;
      width: 80%;
    }
    form {
      width: 80%;
      .form-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        label {
          font-weight: 600;
        }
        input {
          border-radius: 12px;
          background-color: #ffff !important;
          border: unset;
          height: 30px;
          color: gray;
          padding: 0 20px;
          &::-webkit-input-placeholder {
            color: gray !important;
          }
          &::-moz-placeholder {
            color: gray !important;
          }
          &:-ms-input-placeholder {
            color: gray !important;
          }
          &::-ms-input-placeholder {
            color: gray !important;
          }
        }
      }
      .submit-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;
        height: 30px;
        background-color: #fff;
        color: gray;
        margin: 0 auto;
        gap: 5px;
        span {
          font-weight: bold;
        }
      }
    }
  }
}

@media (max-width: 910px) {
  .right-content {
    grid-template-rows: 55% 45%;
  }
}

@media (max-width: 700px) {
  .right-content {
    grid-area: rightContent;
    border-left: unset;
    grid-template-rows: 60% 40%;
    row-gap: 16px;
  }
}

@media screen and (min-width: 768px) {
  .bd-container {
    margin-left: auto;
    margin-right: auto;
  }
}

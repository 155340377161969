.session-form {
  .ant-modal-content {
    border-radius: 10px;
    background-color: #1a1a1a;
    color: #fff;
    padding: 2rem;
    border-radius: 16px;
    box-shadow: 0 4px 12px rgba(1, 1, 1, 0.5);
    .ant-modal-body {
      padding: 0;
    }
    .ant-modal-header {
      background: none !important;
      .ant-modal-title {
        text-align: center;
        color: #bc62ff;
        font-size: clamp(1.5rem, 2vw, 2rem);
        font-weight: bold;
      }
    }
    .anticon-close {
      svg {
        color: #fff !important;
      }
    }
  }

  label {
    color: #fff !important;
    font-weight: 500;
    font-size: 14px;
  }
  .form-control {
    border-radius: 5px;
    background-color: unset;
    color: #fff;

    &::placeholder {
      color: #fff;
      opacity: 1;
    }
  }

  .custom-input,
  .custom-input:focus,
  .custom-input:hover,
  .ant-select-selector {
    appearance: none;
    background-color: unset !important;
    color: white !important;
    border-color: gray !important;
    outline: none;
  }

  .ant-picker-suffix,
  .ant-select-selection-item-remove svg {
    color: #fff;
  }

  .ant-input-number {
    input {
      padding: 0 10px;
      color: #fff;
    }
  }

  .btn {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
    width: 40%;

    &.submit-btn {
      background: linear-gradient(45deg, #bc62ff, #3cbabb);
      &:disabled {
        background: linear-gradient(45deg, #3cbabb, #bc62ff);
      }
    }
  }
}

.ant-picker-time-panel-container {
  .ant-picker-panel {
    height: 100px;
    .ant-picker-time-panel {
      height: 100px;
    }
    .ant-picker-time-panel-column {
      height: 80px;
    }
  }
}

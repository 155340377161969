@import "../../assets/styles/variables.scss";
@import "../../assets/styles/media.scss";

.main-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 15px 0 0 15px;
  border-right: 1px solid $primary;
  padding: 30px 20px;
  overflow: hidden;
  font-family: inherit;
  height: calc(100vh - 60px);
  cursor: pointer;
  @include phone {
    display: none;
  }

  .logo-info {
    display: none;
    justify-content: center;
    margin: 20px 0;
    p {
      color: #fff;
      font-size: clamp(0.8rem, 3vw, 1rem);
      font-weight: 500;
      text-align: center;
      line-height: 1;
      padding: 0 6px 32px;
    }
  }
  ul {
    padding: 0;
  }
  .nav-item {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1rem;
    padding: 12px 0;
    border-radius: 5px;
    gap: 15px;
    &.active {
      background: rgba(106, 109, 155, 0.5);
      text-decoration: none;
    }
    .nav-text {
      display: block;
      width: 130px;
      letter-spacing: 0;
      @include desk-l {
        display: none;
      }
    }
    .nav-icon {
      width: 40px;
      height: 20px;
      font-size: 1.1rem;
      @media (max-width: 1500px) {
        text-align: center;
        transform: translateY(2px);
      }
    }
  }
}

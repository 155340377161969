@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(2px);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  .loading-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    .spinner {
      font-size: 48px;
      color: $primary;
      animation: rotate 1s linear infinite;
    }
    .message {
      margin-top: 24px;
      font-size: 18px;
      font-weight: 600;
    }
  }
}

.clips-gallery {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  flex-wrap: wrap;

  p {
    text-align: center;
    color: #fff;
  }

  .clip-container {
    width: 300px;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;

    .clip-player {
      width: 100%;
      height: auto;
      border-radius: 8px;
    }
  }
}

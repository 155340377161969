.checkout-modal {
  .modal-content {
    border-radius: 10px;
    background-color: #1a1a1a;
    color: #fff;
    padding: 2rem;
    border-radius: 16px;
    box-shadow: 0 4px 12px rgba(1, 1, 1, 0.5);
    .modal-body {
      padding: 0;
    }
    .title {
      color: #bc62ff;
      font-size: clamp(1.5rem, 2vw, 2rem);
      font-weight: bold;
    }
    .session-info {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-bottom: 20px;
      .co-host-images {
        display: flex;
        align-items: center;
        .co-host-image {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          object-fit: cover;
        }
      }
    }
  }
  .form-label {
    color: #ddd;
  }

  .form-control,
  .form-select {
    background-color: unset !important;
    color: #fff;
  }

  .form-select option {
    background-color: black !important;
    color: white;
  }

  .form-control:focus,
  .form-select:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.25);
  }

  .btn-close {
    color: #fff;
    background: none;
    border: none;
    font-size: 1.25rem;
  }

  .btn-secondary {
    background-color: #555;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
    &:disabled {
      background-color: #777;
    }
  }

  .btn-primary {
    background: linear-gradient(45deg, #bc62ff, #3cbabb);
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
    &:disabled {
      background-color: #777;
    }
  }
}

@media (max-width: 768px) {
  .checkout-modal {
    .modal-dialog {
      max-width: 100%;
      margin: 20px;
    }
  }
}

.container-connect {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 2rem;
  height: 70dvh;
  .stripe_logo {
    height: 30%;
  }
  .stripe-text {
    width: 80%;
    color: #fff;
    text-align: center;
  }
  .stripe_button {
    background-color: #3cbabb;
    border: none;
    border-radius: 10px;
    font-size: large;
    font-weight: 600;
    color: #fff;
    width: 200px !important;
    padding: 6px 12px;
    transition: 300ms ease-in-out;
    &:hover {
      background-color: #bc62ff;
    }
  }
}

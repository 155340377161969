.confirmation-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 20px auto;
  padding: 20px;
  color: #fff;

  h2 {
    margin-bottom: 20px;
  }

  p {
    display: flex;
    gap: 15px;
    font-size: 16px;
    line-height: 1.5;

    strong {
      font-weight: bold;
    }
  }

  .btn {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }

  .confirm-btn {
    background-color: #bc62ff;
    color: white;

    &:hover {
      background-color: #bc62ff;
    }
  }
}

.profileForm {
  display: flex;
  justify-content: center;
  .ant-form {
    background: linear-gradient(to bottom, #2b1055, #7597de);
    box-shadow: inset 2px 2px 1px rgba(251, 251, 254, 0.3),
      inset -2px -2px 1px rgba(251, 251, 254, 0.2);
    border-radius: 1.5rem;
    backdrop-filter: blur(10px);
    width: 80%;
    padding: 1.5rem !important;

    label,
    input,
    .ant-select-selection-placeholder,
    .ant-select-selection-item-content,
    .ant-select-selection-item-remove {
      color: #fff !important;
      border-color: #d9d9d9 !important;
    }
    .ql-editor p,
    .ql-editor.ql-blank::before,
    .ql-picker-label {
      color: #fff !important;
    }
    .ql-stroke {
      stroke: #fff !important;
    }
    .ant-picker,
    .ant-select-selector {
      background: transparent !important;
      border-color: #d9d9d9 !important;
    }
    .ant-upload button {
      background: unset !important;
      border-width: 1px;
      border-style: solid;
      border-color: #d9d9d9 !important;
      color: #fff !important;
    }
  }
  .ant-form-item {
    margin-bottom: 16px;
  }

  .submit-btn {
    display: flex;
    justify-content: center;
    .ant-form-item {
      margin-bottom: 0;
    }
    button {
      background-color: #3cbabb;
      border: none;
      width: 200px !important;
      &:hover {
        background-color: #bc62ff;
      }
    }
  }
}

.ant-upload-list-item {
  .ant-upload-list-item-name {
    color: #fff;
  }
}

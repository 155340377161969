.card__glass {
  position: relative;
  overflow: hidden;
  text-align: center;
  box-shadow: inset 2px 2px 1px rgba(251, 251, 254, 0.3),
    inset -2px -2px 1px rgba(251, 251, 254, 0.2);
  border-radius: 1.5rem;
  backdrop-filter: blur(10px);
  min-width: 250px;
  min-height: 250px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @include phone {
    min-width: 150px;
    min-height: 150px;
  }
  &:hover {
    border: 2px solid $primary;
    .card__social {
      .card__link {
        transform: translateX(-1.5rem);
      }
    }
    .card__overlay {
      bottom: 0;
    }
    .card__data {
      a {
        display: block;
      }
    }
  }
  .card__data {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-bottom: 0.5rem;
    z-index: 1;
    cursor: pointer;
    .card__title {
      font-size: $h3-font-size;
      color: #fff;
      font-weight: bold;
      text-transform: uppercase;
      margin: 0;
      text-shadow: 6px 6px 12px rgba(0, 0, 0, 0.9);
    }

    .card__profession {
      font-size: $small-font-size;
      color: #fff;
      font-weight: 500;
      text-shadow: 6px 6px 12px rgba(0, 0, 0, 0.9);
    }
    a {
      display: none;
      transition: bottom 0.3s ease;
      margin: 0 auto;
      width: 20px;
    }
  }
  .card__social {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 1.5rem 2.5rem;
    z-index: 1;
    .card__link {
      display: block;
      font-size: 1.35rem;
      color: #fff;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
      margin: 1rem 0;
      transform: translateX(-5rem);

      &:nth-child(1) {
        transition: 0.2s;
      }

      &:nth-child(2) {
        transition: 0.4s;
      }

      &:nth-child(3) {
        transition: 0.6s;
      }

      &:nth-child(4) {
        transition: 0.8s;
      }

      &:nth-child(5) {
        transition: 1s;
      }
    }
  }
  .card__overlay {
    position: absolute;
    bottom: -100px;
    left: 0;
    right: 0;
    height: 100px;
    background-color: rgba(0, 0, 0, 0.6);
    transition: bottom 0.3s ease;
  }
}

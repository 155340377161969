.bid-modal {
  .modal-content {
    border-radius: 10px;
    background-color: #1a1a1a;
    color: #fff;
    padding: 2rem;
    border-radius: 16px;
    box-shadow: 0 4px 12px rgba(1, 1, 1, 0.5);
    .modal-body {
      padding: 0;
    }
    .title {
      text-align: center;
      color: #bc62ff;
      font-size: 40px;
      font-weight: bold;
    }
  }

  .form-control {
    border-radius: 5px;
    background-color: unset;
    color: #fff;

    &::placeholder {
      color: #fff;
      opacity: 1;
    }
  }

  .custom-input,
  .custom-input:focus,
  .custom-input:hover,
  .input-group-text {
    appearance: none;
    background-color: unset !important;
    color: white !important;
    border-color: gray !important;
    outline: none;
  }

  .custom-input option {
    background-color: black !important;
    color: white;
  }

  .bid-submit-btn {
    background: linear-gradient(45deg, #bc62ff, #3cbabb);
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
    width: 100%;
    &:disabled {
      background: linear-gradient(45deg, #3cbabb, #bc62ff);
    }
  }
}

@media (max-width: 768px) {
  .bid-modal {
    .modal-dialog {
      max-width: 100%;
      margin: 20px;
    }
  }
}

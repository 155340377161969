@import "../../../../assets/styles/variables.scss";

.user-modal {
  .modal-content {
    border-radius: 20px;
    .user-form {
      background-color: #1a1a1a;
      color: #fff;
      padding: 2rem;
      border-radius: 16px;
      box-shadow: 0 4px 12px rgba(1, 1, 1, 0.5);

      .form-group {
        margin-top: 20px;
        text-align: left;
      }

      .form-row {
        display: flex;
        justify-content: space-between;

        .form-group {
          width: 48%;
        }
      }

      label {
        font-size: 14px;
        font-weight: 500;
        color: #6e6e78;
      }

      input[type="text"],
      input[type="email"],
      input[type="password"],
      input[type="file"] {
        width: 100%;
        border: unset;
        border-bottom: 2px solid #fff;
        background-color: #2c2c2e;
        color: #fff;
        &:focus-visible,
        &:focus,
        &:visited {
          box-shadow: none !important;
          color: #fff !important;
        }
      }

      .error {
        color: #ff4d4d;
        font-size: 12px;
      }

      .submit-btn {
        width: 100%;
        background-color: #1a1b26;
        font-size: 14px;
        font-weight: 500;
        color: $primary;
        padding: 10px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        margin: 20px 0;
        &:disabled {
          background-color: #777;
        }
      }
    }
  }
}

.custom-btn,
.custom-btn *,
.custom-btn :after,
.custom-btn :before,
.custom-btn:after,
.custom-btn:before {
  border: 0 solid;
  box-sizing: border-box;
}

.custom-btn {
  scale: 0.5;
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: button;
  color: #fff;
  cursor: pointer;
  font-size: 100%;
  line-height: 1.5;
  margin: 0;
  -webkit-mask-image: -webkit-radial-gradient(#000, #fff);
  border: 1px solid;
  border-radius: 999px;
  box-sizing: border-box;
  font-weight: 900;
  overflow: hidden;
  padding: 1.2rem 3rem;
  position: relative;

  &:disabled {
    cursor: default;
  }

  &:-moz-focusring {
    outline: auto;
  }

  .original {
    color: #fff;
    display: grid;
    inset: 0;
    place-content: center;
    position: absolute;
    transition: transform 0.3s cubic-bezier(0.87, 0, 0.13, 1);
  }

  &:hover {
    .original {
      transform: translateY(100%);
    }
    span {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .letters {
    display: inline-flex;
  }

  span {
    opacity: 0;
    text-transform: capitalize;
    transform: translateY(-15px);
    transition: transform 0.5s cubic-bezier(0.87, 0, 0.13, 1), opacity 0.5s;
  }
}

@keyframes borderAnimation {
  0% {
    border: 3px solid transparent;
  }
  50% {
    border: 3px solid #bc62ff;
  }
  100% {
    border: 3px solid transparent;
  }
}

// $primary: #bc62ff;
// $secondary: #3cbabb;

.class-card {
  min-width: 300px !important;
  border-radius: 8px;
  animation: borderAnimation 1s infinite;
  padding: 1rem;
  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;

    .co-host-images {
      display: flex;
      align-items: center;
      padding-bottom: 0.5rem;
      margin-bottom: 0.5rem;
      border-bottom: 1px solid #6c757d;
      .co-host-image {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .card-content {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .card-details {
        .class-title,
        .card-time {
          font-size: clamp(0.5rem, 2vw, 0.8rem);
          font-weight: bold;
          color: #6c757d;
        }
        .instructor-name {
          font-size: clamp(0.3rem, 2vw, 0.7rem);
          color: #6c757d;
        }
      }
    }
  }
}

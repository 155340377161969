.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  gap: 15px;

  .page-controls {
    display: flex;
    gap: 5px;

    .btn {
      background-color: transparent;
      min-width: 30px;
      text-align: center;
    }
  }
}

@import "../../assets/styles/variables.scss";
@import "../../assets/styles/media.scss";

.bottom-nav {
  display: none;
  position: fixed;
  bottom: 0;
  width: 100%;
  transition: width 0.5s ease;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: #fff;

  @include phone {
    display: block;
  }
  .menu-links {
    display: flex;
    justify-content: space-around;
    padding: 0;
    margin: 0;
    .nav-link {
      // padding: 10px 5px;
      list-style: none;
      position: relative;
      &:hover .nav-text {
        display: block;
      }
      &:hover,
      &.active {
        color: $primary;
      }
      .icon {
        width: 12px;
        height: 12px;
      }
      span {
        font-size: 12px;
      }
    }
    .nav-text {
      display: none;
      position: absolute;
      left: 50px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 14px;
      color: #fff;
    }
  }
}

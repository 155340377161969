.calendar {
  color: #fff;
  padding: 10px 0;

  .toolbar {
    justify-content: space-between;
    margin-left: 10px;
    padding-bottom: 10px;
    gap: 10px;
    div {
      width: auto;
    }
    .todayBtn {
      color: rgb(255, 255, 255) !important;
      width: fit-content;
      background-color: unset;
      border-color: #3cbabb;
    }
    .iconBtn {
      border: none;
      color: rgb(255, 255, 255) !important;
      width: fit-content;
      background-color: unset;
    }
    .add-new-btn {
      display: flex;
      align-items: center;
      color: #fff;
      border: 6px;
      font-size: 16px;
      background-color: #3cbabb;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #bc62ff;
      }
    }
  }

  .week-view {
    display: flex;
    overflow-x: auto;

    .time-column {
      width: 35px;

      .time-slot {
        height: 60px;
        font-size: clamp(0.5rem, 2vw, 0.7rem);
        color: #bbb;
      }
    }

    .days-column {
      position: relative;
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      width: 100%;
      @media (max-width: 767px) {
        grid-template-columns: repeat(5, 1fr);
      }

      .day-column {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid #ccc;

        .day-header {
          text-align: center;
          width: 100%;
          border-bottom: 1px solid #ccc;
          height: 68px;
          &.is-today {
            p {
              color: #3cbabb;
            }
          }
          p {
            font-size: 12px;
            line-height: 32px;
            text-transform: capitalize;
            color: #fff;
            margin-bottom: 0;
          }
        }

        .day-events {
          width: 100%;
          .hour-slot {
            border-bottom: 1px solid #ddd;
            height: 60px;
            padding: 10px;
            &.selected {
              background-color: rgba(188, 98, 255, 0.5);
              border: 1px solid #bc62ff;
            }
          }

          .event {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            display: flex;
            justify-content: center;
            flex-direction: column;
            background-color: #3cbabb;
            border: 1px solid #3cbabb;
            border-radius: 4px;
            color: white;
            padding: 2px 4px;
            font-size: 12px;
            cursor: pointer;
            &.is-booked {
              background-color: #bc62ff;
              border: 1px solid #bc62ff;
            }
            .title {
              font-weight: bold;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 135px;
              display: block;
            }
            .time,
            .price {
              font-size: clamp(0.5rem, 2vw, 0.7rem);
            }
          }
        }
      }
    }
  }
}

.current-time-indicator {
  position: absolute;
  height: 2px;
  background-color: red;
  width: 100%;
  z-index: 10;
}

.password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-form {
    background: linear-gradient(to bottom, #2b1055, #7597de);
    box-shadow: inset 2px 2px 1px rgba(251, 251, 254, 0.3),
      inset -2px -2px 1px rgba(251, 251, 254, 0.2);
    border-radius: 1rem;
    backdrop-filter: blur(10px);
    padding: 20px;
    width: 75%;
    h2,
    .ant-form-item-label span {
      color: #fff;
    }
    .ant-btn {
      display: flex;
      align-items: center;
      color: #fff !important;
      border: 6px !important;
      font-size: 16px;
      background-color: #3cbabb !important;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #bc62ff !important;
      }
    }
  }
}

@import url("https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap");

.footer-con {
  position: relative;
  margin-top: 40px;
  height: 350px;
  .left-side {
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: absolute;
    left: 100px;
    top: 80px;
    width: 40%;
    .logo {
      font-family: "Alfa Slab One", sans-serif;
      color: $primary;
      font-size: 24px;
    }
    .social-links {
      display: flex;
      gap: 15px;
    }
    .contact-info {
      display: flex;
      flex-direction: column;
      gap: 15px;
      div {
        display: flex;
        gap: 15px;
        align-items: center;
        span {
          color: #fff;
        }
      }
    }
    .copyright {
      font-size: 16px;
      color: #fff;
      margin-top: 40px !important;
    }
  }
}

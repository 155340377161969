.time-picker {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 5px;
  .time-picker-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 10px;
    width: 30%;
    cursor: pointer;
    .time-picker-value {
      font-size: 12px;
      font-weight: bold;
      color: #fff;
    }
  }
  .time-picker-separator {
    font-size: 12px;
    font-weight: bold;
    color: #fff;
  }
}

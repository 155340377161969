@import "../../assets/styles/variables.scss";

.register-modal {
  .modal-content {
    border-radius: 20px;
    .register-form {
      background-color: #1a1a1a;
      color: #fff;
      padding: 2rem;
      border-radius: 16px;
      box-shadow: 0 4px 12px rgba(1, 1, 1, 0.5);
      h1 {
        text-align: center;
        color: $primary;
        font-size: 40px;
        font-weight: bold;
      }

      p {
        font-size: 14px;
        font-weight: 500;
        color: $primary;
        text-align: center;
      }

      .form-group {
        margin-top: 20px;
        text-align: left;
      }

      .form-row {
        display: flex;
        justify-content: space-between;

        .form-group {
          width: 48%;
        }
      }

      label {
        font-size: 14px;
        font-weight: 500;
        color: #6e6e78;
      }

      input[type="text"],
      input[type="email"],
      input[type="password"],
      input[type="file"] {
        width: 100%;
        border: unset;
        border-bottom: 2px solid #fff;
        background-color: #2c2c2e;
        color: #fff;
        &:focus-visible,
        &:focus,
        &:visited {
          box-shadow: none !important;
          color: #fff !important;
        }
      }

      input[type="checkbox"] {
        margin-right: 10px;
      }

      .error {
        color: #ff4d4d;
        font-size: 12px;
      }

      .terms {
        display: flex;
        margin-bottom: 20px;
        input[type="checkbox"] {
          accent-color: #1a1a1a;
          &:checked {
            accent-color: $primary;
          }
        }
        span {
          font-weight: 500;
          font-size: 11px;
          color: #6e6e78;
          a {
            color: #9f5bd6;
            text-decoration: none;
          }
        }
      }
      .submit-btn,
      .login-btn,
      .guest-login-btn {
        width: 100%;
        background-color: #1a1b26;
        font-size: 14px;
        font-weight: 500;
        color: $primary;
        padding: 10px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        margin: 20px 0;
        &:disabled {
          background-color: #777;
        }
      }
      .options {
        font-size: 14px;
        font-weight: 500;
        color: #6e6e78;
        text-align: left;
      }
    }
  }
}

@import "../../../assets/styles/media.scss";

.profile-header {
  header {
    width: 100%;
    background-repeat: no-repeat;
    background-position: 50% 20%;
    background-size: cover;
    min-height: calc(100px + 10vw);
  }

  .profileDetails {
    padding: 10px 20px;
    margin-bottom: 15px;
    position: relative;

    @include tabv {
      width: 80%;
      margin: 0 auto;
      text-align: center;
    }
    @include phonev {
      width: 100%;
    }
    .img__container {
      position: absolute;
      top: -50px;
      left: 14%;
      transform: translateX(-50%);
      @include tabv {
        top: -30%;
        left: 50%;
      }

      img {
        width: 150px;
        height: 150px;
        object-fit: cover;
        border-radius: 10px;
        display: block;
        box-shadow: 1px 3px 12px rgba(0, 0, 0, 0.18);
        @include tabv {
          width: 120px;
          height: 120px;
        }
      }

      span {
        position: absolute;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        bottom: 0;
        right: -5px;
        border: 2px solid #fff;
      }
    }
    .about-section {
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin-left: 25%;
      @include tabv {
        margin-top: 60px;
        margin-left: 0;
      }
      h2 {
        font-weight: 600;
        font-size: 22px;
        margin-bottom: 5px;
        color: #fff;
      }
      .profile-progress-bar {
        display: flex;
        gap: 8px;
        flex-direction: column;

        p {
          font-size: 0.9rem;
          color: #fff;
          margin: 0;
        }
        .progress {
          width: 50% !important;
          height: 10px !important;
          @include tabv {
            margin: 0 auto;
          }
          .progress-bar {
            background-color: #3cbabb !important;
            span {
              font-size: 10px;
            }
          }
        }
      }
      .become-host-btn {
        display: flex;
        align-items: center;
        color: #fff !important;
        border: 6px;
        font-size: 16px;
        background-color: #3cbabb;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        @include tabv {
          margin: 0 auto;
        }

        &:hover {
          background-color: #bc62ff;
        }
      }
    }
  }
}

@keyframes slideIn {
  0% {
    // transform: translateY(100%);
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    // transform: translatetY(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes clipsSlideIn {
  0% {
    // transform: translateY(100%);
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    // transform: translatetY(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.browse-con {
  padding: 20px;
  .browse-head {
    display: flex;
    justify-content: center;
    gap: 20px;
    cursor: pointer;
    padding: 20px;
    // border-bottom: 1px solid $primary;

    .category {
      position: relative;
      height: 100px;
      width: 300px;
      @include phone {
        height: 60px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }

      p {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: bold;
        color: #fff;
        font-size: clamp(0.5rem, 3vw, 1.5rem);
        text-shadow: 6px 6px 12px rgba(0, 0, 0, 1);
        text-align: center;
      }
    }
  }
  .data-section {
    .filter-and-sort {
      margin-bottom: 20px;

      .ant-select {
        width: 200px;
        .ant-select-selection-placeholder,
        .ant-select-selection-item-content,
        .ant-select-selection-item-remove,
        .ant-select-selector {
          color: #fff !important;
          border-color: #d9d9d9 !important;
          background: transparent !important;
        }
      }

      .dropdown {
        width: min-content;
        .dropdown-toggle {
          text-align: left;
          background: transparent;
        }
      }
    }
    .tables {
      cursor: pointer;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 10px;
      animation: slideIn 3s forwards;

      .table-content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        border-radius: 8px;
        width: 200px;
        border: 2px solid transparent;
        &:hover {
          border: 2px solid $primary;
        }
        // height: 250px;
        p {
          color: #fff;
          font-size: clamp(0.75rem, 3vw, 1rem);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 120px;
          padding: 0 0.5rem;
          &.followers {
            font-size: clamp(0.5rem, 3vw, 0.75rem);
            color: rgba(170, 176, 182, 1);
          }
        }
      }
    }
  }

  .tabs {
    display: flex;
    border-bottom: 1px solid $primary;
    .tab-button {
      padding: 10px 20px;
      cursor: pointer;
      background: none;
      border: none;
      outline: none;
      color: #fff;
      font-weight: bold;
      border-bottom: 3px solid transparent;
      transition: border-bottom 0.3s ease;
      &.active {
        color: $primary;
        border-bottom-color: $primary;
      }
    }
  }
  .clips-section {
    .filter-and-sort {
      margin-bottom: 20px;

      .dropdown {
        width: min-content;
        .dropdown-toggle {
          text-align: left;
          background: transparent;
        }
      }
    }

    .clips-list {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      .clip-content {
        width: 300px;
        .clip-tile {
          background-color: unset !important;
          border-radius: 8px;
          overflow: hidden;
          border: 2px solid transparent;
          animation: clipsSlideIn 3s forwards;
          &:hover {
            border: 2px solid $primary;
          }

          .image-container {
            position: relative;
            height: 120px;
            width: 100%;
            .card-img-top {
              object-fit: fill;
              border-top-left-radius: 8px;
              border-top-right-radius: 8px;
              height: 100%;
              width: 100%;
            }

            .live-badge {
              top: 8px;
              left: 8px;
              font-size: 0.75rem;
              padding: 0.25rem 0.5rem;
              background-color: rgba(7, 8, 9, 0.8);
              &.bottom {
                top: unset;
                bottom: 8px;
              }
            }
          }

          .card-body {
            padding: 0.75rem;

            .profile-icon {
              width: 24px;
              height: 24px;
              border-radius: 50%;
              object-fit: cover;
            }

            h6 {
              font-size: 1rem;
              margin: 0;
              color: #ffffff;
            }

            .text-muted {
              color: #aaa !important;
            }
          }
        }
      }
    }
  }
}

.chat-area {
  position: relative;
  width: 90%;
  margin: 0 auto;
  height: 90vh;
  overflow: hidden;
  min-height: calc(100% - 1rem);
  background: linear-gradient(to bottom, #2b1055, #7597de);
  box-shadow: inset 2px 2px 1px rgba(251, 251, 254, 0.3),
    inset -2px -2px 1px rgba(251, 251, 254, 0.2);
  border-radius: 1rem;
  backdrop-filter: blur(10px);
  border: 1px solid #bc62ff;
  .row {
    height: 100vh;
    .left-side {
      border-right: 1px solid #bc62ff;
      .chat-header {
        padding: 20px 0 5px 0;
        margin-left: 15px;
        .search-con {
          border: 1px solid #494d55;
          color: #fff !important;
          margin-bottom: 5px;
          input {
            color: white;
            background-color: transparent;
            border: none;
            outline: none;
            box-shadow: none;

            &:focus {
              outline: none;
              box-shadow: none;
            }

            &::placeholder {
              color: rgba(255, 255, 255, 0.6);
            }
          }
        }
        .wrapper {
          width: 100%;
          display: flex;
          gap: 15px;
          padding: 5px 0;

          .tab {
            display: inline-block;
            width: 30%;
            text-align: center;
            cursor: pointer;
            font-size: 16px;
            font-weight: bold;
            line-height: normal;
            user-select: none;
            color: #ffffff;
            border-bottom: 2px solid transparent;
            &.active {
              border-bottom: 2px solid #bc62ff;
            }
          }
        }
      }
      .user-lists {
        height: calc(100vh - 115px);
        overflow-y: scroll;
        padding: 0 5px;
        margin-left: 10px;
        .list {
          padding: 5px;
          margin-bottom: 5px;
          cursor: pointer;
          &.active,
          &:hover {
            background: linear-gradient(to bottom, #2b1055, #7597de);
            box-shadow: inset 2px 2px 1px rgba(251, 251, 254, 0.3),
              inset -2px -2px 1px rgba(251, 251, 254, 0.2);
            border-radius: 1rem;
            backdrop-filter: blur(10px);
          }
          .user-img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            object-fit: cover;
          }
          h5 {
            font-size: 16px;
            color: #fff;
            margin: 0;
          }
          .last-msg {
            font-size: 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 200px;
            display: block;
            color: #fff;
            margin: 0;
            @media (min-width: 992px) and (max-width: 1200px) {
              width: 100px;
            }
          }
        }
      }
    }
    .chatbox {
      display: flex;
      flex-direction: column;
      height: 100vh;
      .chat-header {
        padding: 20px 0;
        height: 70px;
        border-bottom: 2px solid #bc62ff;
        .user-img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          object-fit: cover;
        }
        span {
          color: #fff;
          font-size: clamp(1rem, 2vw, 2rem);
        }
      }
      .msg-body {
        height: calc(100vh - 250px);
        overflow-y: scroll;
        overflow-x: hidden;
        @media (max-width: 767px) {
          height: calc(100vh - 270px);
        }
        ul {
          padding: 0 2rem;
          li {
            list-style: none;
            margin: 15px 0;
            .sender-name {
              font-weight: bold;
              font-size: clamp(0.75rem, 2vw, 0.9rem);
            }
            &.sender {
              display: block;
              width: 100%;
              position: relative;
              // &:before {
              //   display: block;
              //   clear: both;
              //   content: "";
              //   position: absolute;
              //   top: -6px;
              //   left: -7px;
              //   width: 0;
              //   height: 0;
              //   border-style: solid;
              //   border-width: 0 12px 15px 12px;
              //   border-color: transparent transparent #f5f5f5 transparent;
              //   transform: rotate(-37deg);
              // }
              div {
                color: #000;
                font-size: 14px;
                line-height: 1.5;
                font-weight: 400;
                padding: 15px;
                background: #f5f5f5;
                display: inline-block;
                border-bottom-left-radius: 10px;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                margin-bottom: 0;
              }
            }
            &.repaly {
              display: block;
              width: 100%;
              text-align: right;
              position: relative;
              // &:before {
              //   display: block;
              //   clear: both;
              //   content: "";
              //   position: absolute;
              //   bottom: 15px;
              //   right: -7px;
              //   width: 0;
              //   height: 0;
              //   border-style: solid;
              //   border-width: 0 12px 15px 12px;
              //   border-color: transparent transparent #3cbabb transparent;
              //   transform: rotate(37deg);
              // }
              // &:after {
              //   display: block;
              //   content: "";
              //   clear: both;
              // }
              .time {
                margin-right: 10px;
              }
              div {
                color: #fff;
                font-size: 14px;
                line-height: 1.5;
                font-weight: 400;
                padding: 15px;
                background: #3cbabb;
                display: inline-block;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                border-bottom-left-radius: 10px;
                margin-bottom: 0;
              }
            }
            .time {
              display: block;
              color: #fff;
              font-size: 12px;
              line-height: 1.5;
              font-weight: 400;
              &.repaly {
                margin-right: 20px;
              }
            }
            .line-with-text {
              display: flex;
              align-items: center;
              text-align: center;
              &::before,
              &::after {
                content: "";
                flex: 1;
                border-top: 1px solid #fff;
              }
              span {
                margin: 0 10px;
                color: #fff;
              }
            }
          }
        }
      }
      .message-input {
        display: flex;
        align-items: center;
        .ant-row {
          width: 100%;
          margin: 0 5px;
        }
        .ant-btn {
          border-radius: 50%;
          padding: 8px;
        }
        .ant-input {
          border-radius: 20px;
          padding: 10px;
          resize: none;
          width: 100%;
        }
        .ant-btn-primary {
          background-color: #3cbabb;
          border-radius: 50%;
          padding: 8px;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .chatbox {
    display: none;
  }

  .mobile-chat-active {
    display: block;
  }
}

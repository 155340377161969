@import "../../assets/styles/media.scss";

$primary-color: #fff;
$secondary-color: #a2a1b4;
$button-bg: #f2f4ff;
$button-primary-bg: #4b5ade;
$white: #ffffff;
$shadow-color: rgba(0, 0, 0, 0.05);

.notification-wrapper {
  .notification-panel {
    .notification-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 30px;
      border-bottom: 1px solid $secondary-color;

      h3 {
        font-size: 22px;
        color: $primary-color;
        margin: 0;
      }

      .mark-read {
        background-color: $button-bg;
        border: none;
        color: $button-primary-bg;
        padding: 8px 20px;
        border-radius: 30px;
        cursor: pointer;
        font-size: 16px;

        &:hover {
          background-color: darken($button-bg, 10%);
        }
      }
    }

    .notification-item {
      display: flex;
      align-items: center;
      padding: 20px;
      border-bottom: 1px solid $secondary-color;

      &:last-child {
        border-bottom: none;
      }

      .user-img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 20px;
        object-fit: cover;
      }

      .notification-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-grow: 1;
        font-size: 18px;

        p {
          color: $primary-color;
          margin-bottom: 20px;
        }

        .time {
          color: $secondary-color;
        }
      }
    }
  }

  @media (max-width: 780px) {
    .notification-panel .notification-header {
      gap: 20px;

      h3 {
        font-size: 18px;
      }

      .mark-read {
        font-size: 14px;
        padding: 6px 12px;
      }
    }

    .notification-panel .notification-item {
      gap: 10px;
      .notification-content {
        font-size: 16px;

        p {
          font-size: 16px;
          max-width: 100%;
        }

        .time {
          font-size: 14px;
        }
      }
    }
  }
}

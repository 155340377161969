.participants-container {
  color: #fff;
  padding: 20px;
  border-radius: 8px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h2 {
      margin: 0;
      font-size: 1.5rem;
    }

    .actions {
      display: flex;
      gap: 10px;

      .search-input {
        max-width: 200px;
        color: #fff !important;
      }

      .sort-select {
        max-width: 200px;
        background-color: transparent !important;
        color: #fff !important;
        option {
          color: #000 !important;
        }
      }
    }
  }

  .table {
    text-align: center;
    --bs-table-color: #fff;
    --bs-table-bg: transparent;
    --bs-table-border-color: #bc62ff;
    --bs-table-striped-bg: #bc62ff;
    --bs-table-striped-color: #fff;
    --bs-table-active-bg: #bc62ff;
    --bs-table-active-color: #fff;
    --bs-table-hover-bg: transparent;
    --bs-table-hover-color: #fff;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color);
    tbody {
      tr {
        td {
          vertical-align: middle;
          .customer-info {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            img {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}

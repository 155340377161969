@import "../../../../assets/styles/variables.scss";

.category-modal {
  .modal-content {
    border-radius: 20px;
    .category-form {
      background-color: #1a1a1a;
      color: #fff;
      padding: 2rem;
      border-radius: 16px;
      box-shadow: 0 4px 12px rgba(1, 1, 1, 0.5);

      label {
        margin-bottom: 0.5rem;
        font-size: 14px;
        font-weight: bold;
        color: #fff;
      }

      input[type="text"] {
        width: 100%;
        border: unset;
        border-bottom: 2px solid #fff;
        background-color: #2c2c2e;
        color: #fff;
        margin-bottom: 10px;
        &:focus-visible,
        &:focus,
        &:visited {
          box-shadow: none !important;
          color: #fff !important;
        }
      }

      ul {
        margin-top: 10px;
        padding-left: 0;
        list-style: none;
        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;
          margin-bottom: 10px;
          button {
            background: none;
            color: #fff;
            border: none;
            border-radius: 4px;
            cursor: pointer;
          }
        }
      }
      .error {
        color: #ff4d4d;
        font-size: 12px;
      }

      .cancel-btn,
      .submit-btn {
        width: 100%;
        background-color: #1a1b26;
        font-size: 14px;
        font-weight: 500;
        color: $primary;
        padding: 10px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        margin: 20px 0;
        &:disabled {
          background-color: #777;
        }
      }
      .cancel-btn {
        background-color: #6c757d !important;
        color: #fff;
      }
    }
  }
}

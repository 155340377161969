.session-detail-modal {
  .ant-modal-content {
    border-radius: 10px;
    background-color: #1a1a1a;
    color: #fff;
    padding: 2rem;
    border-radius: 16px;
    box-shadow: 0 4px 12px rgba(1, 1, 1, 0.5);
    .ant-modal-close-x {
      color: #fff;
    }
    .modal-title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 15px;
    }

    .modal-details {
      display: flex;
      flex-direction: column;
      gap: 15px;

      .detail-item {
        display: flex;
        align-items: center;
        gap: 8px;

        &.guests {
          font-size: 14px;
          color: #fff;
        }
      }
      .host-list {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-left: 25px;
        .host-detail {
          display: flex;
          gap: 15px;
          align-items: center;
          .host-img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            object-fit: cover;
          }
        }
      }
      .action {
        display: flex;
        justify-content: space-between;
        button {
          display: flex;
          align-items: center;
          color: #fff;
          border: 6px;
          font-size: 16px;
          background-color: #3cbabb;
          border-radius: 5px;
          cursor: pointer;
          transition: background-color 0.3s ease;
          padding: 5px;
          &:hover {
            background-color: #bc62ff;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .modal-content {
      padding: 15px;

      .modal-title {
        font-size: 16px;
      }
    }
  }
}

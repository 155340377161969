// @import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900;1000&family=Roboto:wght@300;400;500;700&display=swap");
@import "react-toastify/dist/ReactToastify.css";
@import "./variables.scss";
@import "./media.scss";
@import "./home.scss";
@import "./footer.scss";
@import "./loader.scss";
@import "./set-password.scss";
@import "./browse.scss";
@import "./customSlider.scss";
@import "./userTile.scss";
@import "./profile.scss";
@import "./custom-btn.scss";
@import "./bookingModal.scss";
@import "./imageGallery.scss";

*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

img {
  max-width: 100%;
}

h1 {
  font-size: clamp(1.2rem, 3vw, 1.5rem) !important;
  font-weight: bold;
}

body {
  font-family: "Poppins", sans-serif !important;
  min-height: 100vh;
  background-image: url("../images/background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
}

p {
  margin: 0 !important;
}

input {
  background-color: unset !important;
  &::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #fff !important;
  }

  &::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #fff !important;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #fff !important;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #fff !important;
  }
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-track {
  background: $primary;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: $primary;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: $primary;
}

main {
  .body {
    display: grid;
    grid-template-columns: 11% 89%;
    width: 100%;
    background: rgba(16, 21, 61, 0.5);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    z-index: 10;
    margin: 0;
    @include desk-l {
      grid-template-columns: 6% 94%;
    }
    @include tabv {
      grid-template-columns: 9% 91%;
    }
    @include phone {
      grid-template-columns: 100%;
    }
  }
}

.brand-logo {
  width: 140px;
}

.content-body {
  height: calc(100vh - 60px);
  overflow-y: scroll;
  @include phone {
    height: calc(100vh - 120px);
  }
}

table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
  tr {
    border: 1px solid #ddd;
    padding: 0.35em;
    th,
    td {
      padding: 0.625em;
      text-align: center;
    }
    th {
      font-size: 0.85em;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }
  }
}

@media screen and (max-width: 600px) {
  table {
    border: 0;

    thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: 0.625em;
      td {
        border-bottom: 1px solid #ddd;
        display: block;
        font-size: 0.8em;
        text-align: right;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &::before {
          content: attr(data-label);
          float: left;
          font-weight: bold;
          text-transform: uppercase;
        }
        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
}

.Toastify__toast-container {
  margin-bottom: 20px !important;
}

nav {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  ul,
  ul li {
    outline: 0;
  }
  ul li a {
    text-decoration: none;
  }
}

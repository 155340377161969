.header__wrapper {
  height: calc(100vh - 60px);
  overflow-y: scroll;
  @include phone {
    height: calc(100vh - 120px);
  }
  header {
    width: 100%;
    background-repeat: no-repeat;
    background-position: 50% 20%;
    background-size: cover;
    min-height: calc(100px + 10vw);
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    a {
      text-decoration: none;
    }
  }

  .cols__container {
    margin: 0 auto;
    width: 90%;
    justify-content: space-between;
    display: grid;
    grid-template-columns: 30% 70%;
    gap: 20px;

    @include tabv {
      grid-template-columns: 100%;
      gap: 0;
    }

    .left__col {
      padding: 25px 20px;
      text-align: center;
      position: relative;

      @include tabv {
        width: 80%;
        margin: 0 auto;
      }
      @include phonev {
        width: 100%;
      }
      .img__container {
        position: absolute;
        top: -80px;
        left: 50%;
        transform: translateX(-50%);
        @include tabv {
          top: -60px;
        }

        img {
          width: 150px;
          height: 150px;
          object-fit: cover;
          border-radius: 10px;
          display: block;
          box-shadow: 1px 3px 12px rgba(0, 0, 0, 0.18);
          @include tabv {
            width: 120px;
            height: 120px;
          }
        }

        span {
          position: absolute;
          background: #2afa6a;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          bottom: 0;
          right: -5px;
          border: 2px solid #fff;
        }
      }

      .about-section {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-top: 60px;
        h2 {
          font-weight: 600;
          font-size: 22px;
          margin-bottom: 5px;
          color: #fff;
        }

        p {
          font-size: 0.9rem;
          color: #fff;
          margin: 0;
        }

        .action-buttons {
          display: flex;
          gap: 15px;
          justify-content: center;
          align-items: center;
          margin: 10px 0;

          .btn {
            display: flex;
            align-items: center;
            color: #fff;
            border: 6px;
            font-size: 16px;
            background-color: $secondary;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s ease;

            &:hover {
              background-color: $primary;
            }
          }
        }

        .popularity {
          justify-content: space-between;
          position: relative;

          li {
            display: flex;
            flex-direction: column;
            color: #fff;
            font-size: 0.9rem;

            span {
              color: #fff;
              font-weight: 600;
            }
          }

          &:after {
            position: absolute;
            content: "";
            bottom: -16px;
            display: block;
            background: $secondary;
            height: 1px;
            width: 100%;
          }
        }
      }
    }

    .right__col {
      nav {
        display: flex;
        padding: 30px 0;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $secondary;
        @include tabv {
          padding: 15px 0;
        }

        ul {
          display: flex;
          gap: 20px;
          flex-direction: row;

          li {
            p {
              color: #fff;
              font-size: clamp(0.9rem, 2vw, 1.2rem);
              font-weight: bold;
            }
            &.active {
              p {
                color: $secondary;
              }
            }
          }
        }
      }

      .clips-section {
        padding: 30px 0;

        .clips-list {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          gap: 20px;
          .clip-content {
            width: 300px;
            .clip-tile {
              background-color: unset !important;
              border-radius: 8px;
              overflow: hidden;
              border: 2px solid transparent;
              &:hover {
                border: 2px solid $primary;
              }

              .image-container {
                position: relative;
                height: 120px;
                width: 100%;
                .card-img-top {
                  object-fit: fill;
                  border-top-left-radius: 8px;
                  border-top-right-radius: 8px;
                  height: 100%;
                  width: 100%;
                }

                .live-badge {
                  top: 8px;
                  left: 8px;
                  font-size: 0.75rem;
                  padding: 0.25rem 0.5rem;
                  background-color: rgba(7, 8, 9, 0.8);
                  &.bottom {
                    top: unset;
                    bottom: 8px;
                  }
                }
              }

              .card-body {
                padding: 0.75rem;

                .profile-icon {
                  width: 24px;
                  height: 24px;
                  border-radius: 50%;
                  object-fit: cover;
                }

                h6 {
                  font-size: 1rem;
                  margin: 0;
                  color: #ffffff;
                }

                .text-muted {
                  color: #aaa !important;
                }
              }
            }
          }
        }
      }

      .user-content {
        padding: 30px 0;
        p {
          font-size: 1rem;
          color: #fff;
          line-height: 1.8em;
          text-align: justify;
        }

        ul {
          gap: 30px;
          justify-content: center;
          align-items: center;
          margin-top: 25px;

          li {
            display: flex;
          }

          i {
            font-size: 1.3rem;
          }
        }
      }
    }
  }
}

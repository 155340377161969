.friends-feeds-page {
  height: calc(100vh - 60px);
  overflow-y: scroll;
  padding: 20px;
  .header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid #fff;
    h1 {
      color: #fff;
    }
  }
  .friends-list {
    .content {
      display: flex;
      flex-wrap: wrap;
      gap: 1.5rem;
      padding: 1rem 0;
      justify-content: center;
    }
  }
  .appointments-list {
    .content {
      display: flex;
      gap: 1.5rem;
      overflow-x: auto;
      padding: 1rem 0;
    }
  }
}
